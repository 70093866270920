import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import { CenteredContent, StyledLink } from "../components/utils"
import Seo from "../components/seo"

const S = {}
S.Paragraph = styled.p`
  font-size: 22px;
`

const SuccessPage = () => (
  <Layout maxWidth="60%">
    <Seo
      title="Wiadomość wysłana"
      meta={[{ name: "robots", content: "noindex" }]}
    />
    <CenteredContent>
      <S.Paragraph>
        Twoja wiadomość została wysłana!
        <br />
        Odpowiemy na nią w ciągu kilku dni roboczych.
      </S.Paragraph>
      <p>
        <StyledLink to="/">Wróć do strony głównej</StyledLink>
      </p>
    </CenteredContent>
  </Layout>
)

export default SuccessPage
